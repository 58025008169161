<template>
    <el-dialog title="藏品详情" @close="close" :append-to-body="true" :fullscreen="true" :close-on-click-modal="false"
               :visible.sync="visible">
        <div class="plate flex_b_c">
            <span><i class="el-icon-s-flag"></i>藏品基础信息</span>
            <i class="el-icon-edit pointer" @click="disableClick"></i>
        </div>
        <div class="flex_l_t">
            <el-upload
                    class="avatar-uploader"
                    :action="uploadUrl"
                    :headers="myHeaders"
                    :show-file-list="false"
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <div v-else>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <div>上传缩略图</div>
                </div>
            </el-upload>
            <el-form size="small" label-width="120px" :disabled="method==='view'">
                <el-row :gutter="10">
                    <el-col :span="8" v-for="(item, index) in columnList" :key="index">
                        <el-form-item :label="item.fieldName"
                                      v-if="item.fieldEname != 'id' && item.fieldEname != 'thumb_path' && item.fieldEname != 'collection_num'">
                            <el-col :span="22">
                                <el-input ref="input" v-if="item.fieldEname=='collection_num'" :maxlength="20"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_name'" :maxlength="200"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_level'" :maxlength="10"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_type'" :maxlength="20"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_years'" :maxlength="100"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_texture'" :maxlength="20"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_texture_type'"
                                          :maxlength="40" :show-word-limit="true"
                                          v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='collection_size'" :maxlength="200"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='insert_type'" :maxlength="1"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='thumb_path'" :maxlength="200"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='publish_state'" :maxlength="1"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else-if="item.fieldEname=='theme_word'" :maxlength="100"
                                          :show-word-limit="true" v-model="detailsForm[item.fieldEname]"></el-input>
                                <el-input ref="input" v-else v-model="detailsForm[item.fieldEname]"></el-input>
                            </el-col>
                            <el-col :span="2">
                                <i class="el-icon-view pointer"
                                   v-if="item.onShow == 0 ? true : false" @click="showField(true, item)"></i>
                                <img src="../../../../assets/img/closeEye.png" class="closeEye pointer" v-else
                                     @click="showField(false, item)">
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="plate"><i class="el-icon-s-flag"></i> 藏品详细信息</div>
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="detailsInfo" maxlength="500" show-word-limit
                  :disabled="method==='view'"></el-input>
        <div class="plate"><i class="el-icon-s-flag"></i>关联资源</div>
        <el-tabs v-model="activeName" @tab-click="handleClickTab">
            <el-tab-pane :label="item.name" :name="item.id" v-for="item in tabArr" :key="item.id"></el-tab-pane>
        </el-tabs>
        <div class="modelBox">
            <el-row :gutter="10" class="h100">
                <el-col :span="17" class="h100">
                    <!-- 没有数据占位-->
                    <div v-if="!info">暂无数据</div>
                    <!--图片-->
                    <el-image :src="info.url" class="h100 w100" fit="contain"
                              v-if="fileType == 'picture' && info"></el-image>
                    <!--三维-->
                    <iframe id="mainIframe" ref="mainIframe" name="mainIframe" class="iframe"
                            :src="iframeSrc" frameborder="0" width="100%" height="100%"
                            v-if="fileType == 'three' && info"/>
                    <div class="text_right" v-if="fileType == 'three' && info">
                        <el-select class="m_l1" v-model="modelType" @change="changeSelect" placeholder="请选择"
                                   style="width: 150px">
                            <el-option
                                    v-for="item in modelTypeOpt"
                                    :key="item.id"
                                    :label="item.fieldName"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <!--视频-->
                    <video v-if="fileType == 'video' && info" class="w100 h100" controls :src="info.url"></video>
                    <!--音频-->
                    <div v-if="fileType == 'audio'&& info" class="text_center">
                        <img src="../../../../assets/img/audioIcon.png"
                             :class="[audioIsPlay ? 'audioIcon rotateAudio' : 'audioIcon']">
                        <audio class="w100" controls :src="info.url" @play="playAudio" @pause="pauseAudio"></audio>
                    </div>
                    <!--pdf-->
                    <div v-if="otherFileType == 'pdf' && info" class="w100 h100">
                        <div class="pdfBox">
                            <pdf :src="info.url" :page="currentPage"
                                 @num-pages="pageCount=$event"
                                 @page-loaded="currentPage=$event"
                                 @loaded="loadPdfHandler">
                            </pdf>
                        </div>
                        <p class="text_center">
                                <span @click="changePdfPage(0)">
                                    <i class="el-icon-caret-left pointer" style="font-size: 20px"></i>
                                </span>
                            <span style="position: relative;bottom: 2px">{{currentPage}} / {{pageCount}}</span>
                            <span @click="changePdfPage(1)">
                                    <i class="el-icon-caret-right pointer" style="font-size: 20px"></i>
                                </span>
                        </p>
                    </div>
                    <!--word-->
                    <div class="word-wrap w100 h100" v-if="otherFileType === 'docx'">
                        <div id="wordView" v-html="wordText"/>
                    </div>
                    <!--xlsx-->
                    <div class="xlsx-wrap w100 h100" v-if="otherFileType === 'xlsx'" v-html="xlsxText"></div>
                </el-col>
                <el-col :span="7" class="videoList h100" v-if="info">
                    <el-table :data="dataList" size="small" height="calc(100vh - 230px)" class="table pointer"
                              @row-click="clickRow" ref="dataListTable">
                        <el-table-column prop="fileName" v-if="fileType != 'three' || threeM == false"
                                         show-overflow-tooltip
                                         label="文件名称"></el-table-column>
                        <el-table-column v-if="fileType == 'three' && threeM == true" width="80" label="当前预览">
                            <template slot-scope="scope">
                                <i class="el-icon-check" v-if="scope.row.threeMaster==1 || threeId == scope.row.id"></i>
                                <!--<i class="el-icon-check" v-else-if="dataList[0].id==scope.row.id"></i>-->
                            </template>
                        </el-table-column>
                        <el-table-column prop="resourceName" v-if="fileType == 'three' && threeM == true"
                                         show-overflow-tooltip
                                         label="关联三维列表"></el-table-column>
                        <el-table-column v-if="fileType == 'three' && threeM == true" width="80" show-overflow-tooltip
                                         label="三维预览" fixed="right">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" plain
                                           @click="updateThreeMaster(scope.row.id,1,null)">查看
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="on_show" width="120" label="是否隐藏" v-if="fileType == 'picture'">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.onExhibit"
                                           :active-value="1" :inactive-value="0"
                                           @change="isHideChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="on_show" width="80" label="是否发布"
                                         v-if="fileType == 'three' && threeM == true">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.threeMasterPut"
                                           :active-value="1" :inactive-value="0"
                                           @change="updateThreeMaster(scope.row.id,2,scope.row.threeMasterPut)"></el-switch>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="text_center m_t1">
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="total, prev, pager, next">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="close()">关闭</el-button>
            <el-button type="primary" @click="edit()" size="small" v-if="method == 'edit'">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import XLSX from "xlsx";
    import pdf from "vue-pdf";
    import mammoth from "mammoth";
    import Upload from "@/components/Upload/Upload"

    export default {
        components: {pdf, Upload},
        data() {
            return {
                visible: false,
                method: 'view',
                // 藏品基础信息
                id: '', // 藏品ID
                digitalCollectionId: '', // 数字资源藏品ID
                moduleId: '946049935122366464',
                imageUrl: null,
                // imageUrl: '',
                detailsForm: {},
                columnList: [],
                modelTypeOpt: [],
                uploadUrl: this.$globalUrl() + this.api.uploadCommon.uploadFile,
                // 上传文件
                myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
                // 缩略图上传要求
                uploadRequire: {
                    fileType: ['jpg', 'png', 'jpeg']
                },
                // 藏品详细信息
                detailsInfo: '',
                // 关联资源
                relevance: [],
                activeName: '1',
                tabArr: [
                    {
                        id: '1',
                        name: '图片资源',
                        code: 'picture'
                    },
                    {
                        id: '0',
                        name: '三维资源',
                        code: 'three'
                    },
                    {
                        id: '3',
                        name: '视频资源',
                        code: 'video'
                    },
                    {
                        id: '2',
                        name: '音频资源',
                        code: 'audio'
                    },
                    {
                        id: '4',
                        name: '出版物资源',
                        code: 'publicationData'
                    },
                    {
                        id: '5',
                        name: '文献资源',
                        code: 'literatureData'
                    },
                    {
                        id: '6',
                        name: '文件资源',
                        code: 'document'
                    },
                    {
                        id: '7',
                        name: '其他资源',
                        code: 'other'
                    }
                ],
                threeId: '',
                fileType: '',
                otherFileType: '',
                info: {},
                dataList: [],
                modelType: '',
                pageNo: 0,
                pageSize: 10,
                total: 0,
                xlsxText: "",
                wordText: "",
                audioIsPlay: false, // 音频是否播放
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
                fileList: [], // 三维模型文件
                threeFileList: [],
                iframeSrc: '/static/threeJs/examples/test_obj.html',
                threeM: false
            }
        },
        mounted() {
            //this.getColumnList()
        },
        methods: {
            init(row) {
                this.fileType = 'picture'
                this.activeName = '1'
                this.visible = true
                this.method = 'view'
                this.$nextTick(() => {
                    this.detailsForm = {}
                    this.id = row.id
                    this.digitalCollectionId = row.digitalCollectionId
                    this.columnList = []
                    this.getColumnList()
                    this.refreshList()
                    this.getCollResourceInfo()
                    this.getResourceId()
                    // 数字资源缩略图
                    if ((this.imageUrl == null || this.imageUrl == '')
                        && (this.digitalCollectionId != null && this.digitalCollectionId != '')) {
                        this.imageUrl = row.thumb_path
                    }
                })
            },
            disableClick() {
                this.$refs.input.forEach((item) => {
                    item.$refs.input.style.padding = '0 50px 0 15px'
                })

                this.method = 'edit'
            },
            // 关闭
            close() {
                this.$emit('refreshDataList')
                this.imageUrl = null
                this.visible = false
                this.threeM = false
            },
            // 获取显示字段
            getColumnList() {
                this.$axios(this.api.rcPage.getOriginalCollInfo + '?originalId=' + this.moduleId + '&collectionId=' + this.id, {}, "get").then((res) => {
                    if (res.status) {
                        res.data.map(item => {
                            //if (item.logicType == 0) {
                            this.columnList.push(item);
                            //this.$set(item, 'isShow', true)
                            //}
                        })
                        this.getResources();
                    }
                })
            },
            // 上方获取资源信息
            getResources() {
                this.$axios(this.api.rcPage.getCollectionInfo + this.id, {}, "get").then((res) => {
                    if (res.status) {
                        this.detailsForm = res.data
                        this.detailsInfo = res.data.theme_desc
                        if (res.data.thumb_path != null && res.data.thumb_path != '') {
                            this.imageUrl = res.data.thumb_path
                        }
                    }
                })
            },
            // 藏品关联资源文件列表
            getCollResourceInfo() {
                if (this.activeName == 0) {
                    if (this.threeId == null || this.threeId == '') {
                        this.info = null
                        return
                    }
                    this.getResourceInfo()
                    /**
                     * this.threeM = TRUE 表示有多个三维文件关联该藏品
                     * */
                    if (this.threeM) {
                        this.getCollectionThree()
                        return
                    }
                }
                let collectionId = this.id;
                if (this.activeName == 1 && this.digitalCollectionId != null && this.digitalCollectionId != '') {
                    collectionId += ',' + this.digitalCollectionId;
                }
                this.$axios(this.api.rcPage.getCollResourceInfo, {
                    current: this.pageNo,
                    size: this.pageSize,
                    resourceType: parseInt(this.activeName),
                    collectionId: collectionId,
                    modelType: this.modelType
                }, "get").then((res) => {
                    this.dataList = res.data.records
                    this.info = this.dataList[0]
                    this.total = parseInt(res.data.total);
                    this.$refs.dataListTable.doLayout();
                    this.getFileType(this.info.url)
                })
            },
            // 获取文件后缀识别文件类型
            getFileType(data) {
                if (data == null) {
                    return
                }
                if (this.fileType == 'publicationData' || this.fileType == 'literatureData' || this.fileType == 'document' || this.fileType == 'other') {
                    let first = data.lastIndexOf(".");//取到文件名开始到最后一个点的长度
                    let namelength = data.length;//取到文件名长度
                    this.otherFileType = data.substring(first + 1, namelength);//截取获得后缀名
                    if (this.otherFileType == 'xlsx') {
                        this.getXlsxText()
                    }
                    if (this.otherFileType == 'docx') {
                        this.getWordText()
                    }
                    if (this.otherFileType != 'pdf' && this.otherFileType != 'docx' && this.otherFileType != 'xlsx') {
                        this.$message('当前文件暂不支持预览，敬请下载查阅')
                    }
                }
            },
            getWordText() {
                const xhr = new XMLHttpRequest();
                xhr.open("get", this.info.url, true);
                xhr.responseType = "arraybuffer";
                xhr.onload = () => {
                    if (xhr.status == 200) {
                        mammoth.convertToHtml({arrayBuffer: new Uint8Array(xhr.response)}).then((resultObject) => {
                            this.$nextTick(() => {
                                this.wordText = resultObject.value;
                            });
                        });
                    }
                };
                xhr.send();
            },
            getXlsxText() {
                const xhr = new XMLHttpRequest();
                xhr.open("get", this.info.url, true);
                xhr.responseType = "arraybuffer";
                xhr.onload = () => {
                    if (xhr.status == 200) {
                        let workbook = XLSX.read(new Uint8Array(xhr.response), {type: "array"}); // 解析数据
                        var worksheet = workbook.Sheets[workbook.SheetNames[0]]; // workbook.SheetNames 下存的是该文件每个工作表名字,这里取出第一个工作表
                        this.xlsxText = XLSX.utils.sheet_to_html(worksheet); // 渲染
                    }
                };
                xhr.send();
            },
            // 三维资源详情
            getResourceInfo() {
                let param = {
                    resourceId: this.threeId
                }
                this.$axios(this.api.resources.showThreeResource, param, 'get').then(data => {
                    if (data && data.status) {
                        // this.resourceInfo = data.data.resources
                        this.threeFileList = data.data.stringMapMap
                        // this.dynamicTags = data.data.infoLabel
                        this.getFieldByResourceName();
                    }
                })
            },

            getCollectionThree() {
                this.dataList = []
                this.$axios(this.api.rcPage.getCollectionThree, {
                    current: this.pageNo,
                    size: this.pageSize,
                    resourceType: parseInt(this.activeName),
                    collectionId: this.id
                }, "get").then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                        this.info = 1
                        this.$refs.dataListTable.doLayout();
                    }
                })
            },

            getResourceId(type) {
                var that = this;
                let param = {
                    resourceType: 0,
                    collectionId: this.id
                }
                that.$axios(this.api.rcPage.getResourceId1, param, "get").then((res) => {
                    if (res.status) {
                        if (res.data != null) {
                            this.threeId = res.data[0]
                            this.threeM = res.data[1]
                        }
                        if (type == 1) {
                            this.getCollResourceInfo()
                        }
                    }
                })
            },


            // 获取业务字段
            getFieldByResourceName() {
                var that = this;
                let parma = {
                    resourcesId: this.threeId
                }
                that.$axios(this.api.resources.resourceModel, parma, "get").then((res) => {
                    if (res.status) {
                        that.modelTypeOpt = res.data
                        that.modelType = that.modelTypeOpt[that.modelTypeOpt.length - 1].id
                        that.changeSelect(that.modelType)
                    }
                })
            },
            // 显示隐藏字段
            showField(type, item) {
                this.$set(item, 'isShow', !type)
                let onShow = !type === true ? 0 : 1;
                this.$axios(this.api.rcPage.pubFieldUpdate, {
                    originalId: item.id,
                    onShow: onShow,
                    publishId: this.id,
                    masterTableId: this.moduleId
                }, "post").then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.columnList = []
                        this.getColumnList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // tab切换
            handleClickTab(tab, event) {
                this.pageNo = 1;
                this.currentPage = 1;
                this.otherFileType = '';
                this.modelType = ''
                this.refreshList();
                this.fileType = this.tabArr[parseInt(tab.index)].code
                this.getCollResourceInfo()
            },
            // 数据查询
            refreshList() {
                this.fileType = this.tabArr[0].code
                this.info = this.dataList[0]
                /*this.$axios(this.api.rcPage.getResources, {}, 'get').then(data => {
                    if (data.status) {

                    }
                })*/
            },
            // 点击表格行
            clickRow(val) {
                this.currentPage = 1;
                this.info = val;
                /*if (this.fileType == 'three') {
                    this.changeSelect(val.id)
                }*/
                this.getFileType(this.info.url);
            },
            // 确定修改
            edit() {
                this.$confirm(`确定修改吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 修改接口
                    this.$set(this.detailsForm, "originalId", this.moduleId)
                    this.$set(this.detailsForm, "theme_desc", this.detailsInfo)
                    this.$axios(this.api.rcPage.updateCollection, this.detailsForm, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.visible = false
                            // 修改成功刷新列表
                            this.$emit('refreshDataList')
                        }
                    })

                })
            },
            /*changeSelect(id) {
                var that = this
                let newarr = this.threeFileList[id];
                var imgUrl = ''
                var objUrl = newarr.obj.url
                var mtlUrl = newarr.mtl.url
                imgUrl = newarr.img.url
                // 使用ref 获取 dom
                const mapFrame = this.$refs['mainIframe']
                // 因为iframe页面打开就已经加载 获取接口成功后刷新他
                mapFrame.contentWindow.location.reload()
                if (mapFrame.attachEvent) {
                    // 兼容浏览器判断 // iframe的加载完成函数
                    mapFrame.attachEvent('onload', function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage({
                            mtlUrl: mtlUrl,
                            objUrl: objUrl,
                            imgUrl: imgUrl
                        }, '*')
                    })
                } else {
                    // iframe的加载完成函数
                    mapFrame.onload = function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage({
                            mtlUrl: mtlUrl,
                            objUrl: objUrl,
                            imgUrl: imgUrl
                        }, '*')
                    }
                }
                return
            },*/
            changeSelect(id) {
                var that = this
                let newarr = this.threeFileList[id];
                if (id == '955785177131384832') {
                    this.$message('预览高模文件，请保持客户端内存充足')
                }
                if (!newarr) {
                    this.$message('无三维文件，请上传三维文件或设置主显')
                    this.resourceExists = true;
                    return;
                }
                let iframeData
                if (newarr.obj && newarr.mtl && newarr.img) {  // stl模型
                    this.iframeSrc = '/static/threeJs/examples/test_obj.html'
                    this.inputForm = newarr.obj
                    that.objFile = newarr.obj
                    that.mtlFile = newarr.mtl
                    that.imgFile = newarr.img
                    iframeData = {
                        mtlUrl: newarr.mtl.url,
                        objUrl: newarr.obj.url,
                        imgUrl: newarr.img.url,
                    }
                } else if (newarr.stl) {  // stl模型
                    this.iframeSrc = '/static/threeJs/examples/test_stl.html'
                    this.inputForm = newarr.stl
                    iframeData = {
                        stlUrl: newarr.stl.url,
                    }
                } else {
                    this.$message('缺失文件')
                    this.resourceExists = true;
                    return;
                }
                // 使用ref 获取 dom
                const mapFrame = this.$refs['mainIframe']
                // 因为iframe页面打开就已经加载 获取接口成功后刷新他
                mapFrame.contentWindow.location.reload()
                if (mapFrame.attachEvent) {
                    // 兼容浏览器判断 // iframe的加载完成函数
                    mapFrame.attachEvent('onload', function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    })
                } else {
                    // iframe的加载完成函数
                    mapFrame.onload = function () {
                        const iframeWin = mapFrame.contentWindow
                        //传递参数
                        iframeWin.postMessage(iframeData, '*')
                    }
                }
                return
            },
            // 音频播放
            playAudio() {
                this.audioIsPlay = true;
            },
            // 音频暂停
            pauseAudio() {
                this.audioIsPlay = false;
            },
            // pdf加载时
            loadPdfHandler(e) {
                this.currentPage = 1 // 加载的时候先加载第一页
            },
            // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
            changePdfPage(val) {
                if (val === 0 && this.currentPage > 1) {
                    this.currentPage--
                }
                if (val === 1 && this.currentPage < this.pageCount) {
                    this.currentPage++
                }
            },
            updateThreeMaster(id, type, threeMasterPut) {
                this.$axios(this.api.rcPage.updateThreeMaster, {
                    'resourceId': id,
                    'type': type,
                    'threeMasterPut': threeMasterPut,
                    'collectionId': this.id
                }, "get").then((res) => {
                    if (res.status) {
                        if (type == 2) {
                            this.$message.success("操作成功")
                            this.getCollectionThree()
                        } else if (type == 1) {
                            this.getResourceId(1)
                        }
                    }
                })
            },
            // 是否隐藏
            isHideChange(row) {
                this.$axios(this.api.rcPage.setFileExhibit, {
                    'fileId': row.id,
                    'state': row.onExhibit,
                }, "get").then((res) => {
                    if (res.status) {
                        this.$message.success(res.msg)
                        this.getCollResourceInfo()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.getCollResourceInfo()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.getCollResourceInfo()
            },
            // 限制文件大小跟格式
            beforeUpload(file) {
                let that = this;
                let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
                let fileType = ['jpg', 'png', 'jpg', 'jpeg']
                let testType = Boolean(fileType.find(item => item == testmsg) == testmsg)
                if (!testType) {
                    that.$message.error(`上传文件只能是${that.uploadRequire.fileType.join('、')}格式!`);
                    return false
                }
                const isLt2M = file.size / 1024 / 1024 <= 2
                if (!isLt2M) {
                    this.$message.error("上传文件大小不能超过2MB!");
                    return false
                }
            },
            // 文件上传成功时的钩子
            handleSuccess(response, file) {
                if (response.status) {
                    this.imageUrl = response.data.url

                    this.$axios(this.api.rcPage.updateCollection, {
                        id: this.id,
                        thumb_path: response.data.path,
                        originalId: this.moduleId,
                    }, "post").then((res) => {
                        if (res.status) {
                            this.columnList = []
                            this.getColumnList()
                            this.$message.success('上传成功')
                        }
                    })
                } else {
                    this.$message.error(response.msg)
                }
                //this.$emit('refreshDataList')
            }
        }
    }
</script>
<style scoped>
    .avatar-uploader >>> .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        height: 100px;
    }

    .avatar-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }

    .el-icon-edit {
        font-size: 18px;
        font-weight: bold;
    }

    .plate {
        font-size: 1rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    .el-icon-view {
        font-size: 20px;
        vertical-align: middle;
    }

    .closeEye {
        width: 20px;
        vertical-align: middle;
    }

    .el-carousel__item >>> .el-image {
        height: 100%;
    }

    .el-tabs--border-card >>> .el-tabs__content {
        height: calc(100vh - 232px);
        overflow-y: auto;
    }

    .modelBox {
        height: calc(100vh - 200px);
    }

    .el-table >>> .cell {
        text-align: center !important;
    }

    .pdfBox {
        height: 94%;
        overflow-y: auto;
    }

    .pdfBox::-webkit-scrollbar {
        display: none;
    }

    .word-wrap, .xlsx-wrap {
        overflow: auto;
    }

    .word-wrap >>> img, .xlsx-wrap >>> table {
        width: 100% !important;
    }

    .xlsx-wrap >>> tr {
        border-bottom: 1px solid #DCDCDC;
    }

    .xlsx-wrap >>> td {
        padding: 6px;
        text-align: center;
    }
</style>
